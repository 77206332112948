<template>
  <div class="personal">
    <el-form :model="formData" :rules="rules" inline :hide-required-asterisk="true" ref="personal">
      <div class="insert-title">{{ $t('open.t101') }}</div>

      <el-form-item prop="package" class="goods-type" style="width: 100%;">
        <div class="label qjc-fts-14" slot="label">
          {{ $t('open.t11') }}
        </div>
        <div class="package" v-for="(goods, goodsIndex) in goodsList" :key="goodsIndex">
          <div class="package-radio radio-option">
            <div>
              <el-radio v-model="packageValue"
                :disabled="type == 'update' || (pay_mode == 1 && ((goods.times | 0) - (goods.used | 0) <= 0))"
                :label="goods.goods_id" @change="handlePackage(goods)">{{ goods.name
                }}</el-radio>
            </div>

            <div v-if="pay_mode == 1" class="radio-option-num">
              {{ $t('table.t18') }}：{{ goods.used ? goods.used : '0' }} / {{ $t('table.t19') }}：{{ goods.times ?
                goods.times :
                '0' }}
            </div>
          </div>
        </div>
      </el-form-item>

      <div class="insert-title">{{ $t('open.t1') }}</div>

      <el-form-item prop="mobile" class="pd_l">
        <div class="label qjc-fts-14" slot="label">
          {{ $t('open.t5') }}
        </div>
        <div class="open-item-tip" v-show="isShowPhone">{{ formData.mobile }}</div>
        <el-input :disabled="type == 'update'" v-model="formData.mobile" type="text" :placeholder="$t('open.f25')"
          @input="phoneMustNumber"></el-input>
        <p v-if="type == 'insert'" class="tip qjc-fts-12">{{ $t('open.f27') }}</p>
      </el-form-item>
      <el-form-item prop="yyuserid">
        <div class="label qjc-fts-14" slot="label">
          {{ $t('open.t6') }}
        </div>
        <el-input :disabled="type == 'update'" v-model="formData.yyuserid" type="text" :change="check_num()"
          :placeholder="$t('open.f26')"></el-input>
        <p v-if="type == 'insert'" class="tip qjc-fts-12">{{ $t('open.f27') }}</p>
      </el-form-item>
      <el-form-item prop="sex" class="pd_l">
        <div class="label qjc-fts-14" slot="label">
          {{ $t('open.t7') }}
        </div>
        <el-select v-model="formData.sex" :placeholder="$t('open.f28')">
          <el-option :label="$t('open.t14')" :value="1"></el-option>
          <el-option :label="$t('open.t15')" :value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="birth">
        <div class="label qjc-fts-14" slot="label">
          {{ $t('open.t8') }}
        </div>
        <el-date-picker v-model="birthSel" type="date" :placeholder="$t('open.f28')" value-format="yyyy/MM/dd"
          format="yyyy/MM/dd" @change="handleBirth" :default-value="new Date('1990/1/1')">
        </el-date-picker>
      </el-form-item>

      <el-form-item prop="corp" class="pd_l">
        <div class="label qjc-fts-14" slot="label">
          {{ $t('open.t9') }}
        </div>
        <el-input v-model="formData.corp" type="text"></el-input>
      </el-form-item>
      <el-form-item prop="examdate" v-if="!(packageValue == 5 || packageValue == 6)">
        <div class="label qjc-fts-14" slot="label">
          {{ $t('open.t10') }}
        </div>
        <el-date-picker v-model="examdateSel" type="date" :placeholder="$t('open.f28')" value-format="yyyy/MM/dd"
          format="yyyy/MM/dd" @change="handleExamdate" :default-value="new Date()">
        </el-date-picker>
      </el-form-item>


      <div class="footer-btn">
        <!-- <el-button @click="handlePrev" class="prev qjc-fts-16 qjc-block">返回</el-button> -->
        <el-button @click="next" class="next qjc-fts-16 qjc-block">{{ $t('open.t13') }}</el-button>
      </div>

    </el-form>
  </div>
</template>

<script>
import { isPhone } from '@/utils'
import { getPackage, enterpriseList, getUserGoodsList } from '@/utils/axios'

import { Base64 } from 'js-base64'


export default {
  name: 'personal',
  data () {
    // 验证
    // 手机号
    var mobileVerify = (rule, value, callback) => {
      if (this.formData.yyuserid) {
        if (value == '') {
          callback();
        } else if (isPhone(value)) {
          callback();
        } else {
          callback(new Error('Incorrectly formatted mobile phone number'));
        }
      } else {
        if (value == '') {
          callback(new Error(this.$t('open.f27')));
        } else if (isPhone(value)) {
          callback();
        } else {
          callback(new Error('Incorrectly formatted mobile phone number'));
        }
      }
    }

    // 企业用户id验证
    var yyuseridVerify = (rule, value, callback) => {
      if (this.formData.mobile) {
        callback();
      } else {
        if (value == '') {
          callback(new Error(this.$t('open.f27')));
        } else {
          callback();
        }
      }
    }

    // 出生日期验证
    var birthVerify = (rule, value, callback) => {
      let minNum = this.hasFb ? 40 : 20;
      let maxNum = this.hasFb ? 80 : 90;

      if (this.birthSel == '') {
        callback(new Error(this.$t('open.f29')));
      }
      if (this.packageValue == '5') {
        if (this.getAgeBirth(new Date(this.birthSel).getTime()) >= 20 && this.getAgeBirth(new Date(this.birthSel).getTime()) <= 90) {
          callback();
        } else {
          callback(new Error(this.$t('open.a1')));
        }

      } else if (this.packageValue == '6') {
        if (this.getAgeBirth(new Date(this.birthSel).getTime()) >= 40 && this.getAgeBirth(new Date(this.birthSel).getTime()) <= 80) {
          callback();
        } else {
          callback(new Error(this.$t('open.a2')));
        }
      } else if (this.packageValue != '5' && this.packageValue != '6' && (this.examdateSel != '' && this.birthSel != '')) {
        if (this.getAge(new Date(this.birthSel).getTime(), new Date(this.examdateSel).getTime()) >= minNum && this.getAge(new Date(this.birthSel).getTime(), new Date(this.examdateSel).getTime()) <= maxNum) {
          callback();
        } else {
          callback(new Error(this.hasFb ? this.$t('open.a2') : this.$t('open.a1')));
        }
      }

    }

    // 体检日期验证
    var examdateVerify = (rule, value, callback) => {
      if (this.birthSel == '') {
        callback(new Error(this.$t('open.f29')));
      }
      if (this.examdateSel == '') {
        callback(new Error(this.$t('open.f30')));
      } else {
        let nowDate = new Date();
        let nowTime = new Date(nowDate.getFullYear() + '/' + (nowDate.getMonth() + 1) + '/' + nowDate.getDate()).getTime();// 当前日期时间戳
        let birthTime = new Date(this.birthSel).getTime()
        if (nowTime >= new Date(this.examdateSel).getTime() && birthTime < new Date(this.examdateSel).getTime()) {
          callback();
        } else {
          callback(new Error(this.$t('open.f31')));
        }
      }
      if (this.packageValue == '5' || this.packageValue == '6') {
        callback();
      }


    }

    // 基础套餐必选
    var examBasicVerify = (rule, value, callback) => {
      callback()

    }

    return {
      mobileConfirm: '',// 手机号确认
      mobileConfirmErr: false,// 手机号确认错误消息显示

      yyuseridConfirm: '',// 企业用户id确认
      yyuseridConfirmErr: false,// 手机号确认错误消息显示

      rules: {
        mobile: [
          { validator: mobileVerify, trigger: 'blur' }
        ],
        yyuserid: [
          { validator: yyuseridVerify, trigger: 'blur' }
        ],
        // 性别:1男  2女
        sex: [
          { required: true, message: this.$t('open.f49'), trigger: 'blur' }
        ],
        birth: [
          { required: true, message: this.$t('open.f29'), trigger: 'blur' },
          { validator: birthVerify, trigger: 'change' }
        ],
        examdate: [
          { required: true, message: this.$t('open.f30'), trigger: 'blur' },
          { validator: examdateVerify, trigger: 'change' }
        ],
        examplace: [
          { required: false, message: '请输入体检地点', trigger: 'blur' }
        ],
        package: [
          { validator: examBasicVerify, trigger: 'change' }
        ],
      },

      count: {
        base: {
          times: 0//默认为0 
        },
        packageA: {
          times: 0//默认为0
        },
        packageB: {
          times: 0//默认为0
        },
        packageC: {
          times: 0//默认为0
        },
        packageD: {
          times: 0//默认为0
        }
      },//套餐剩余次数
      packageList: [],
      package_opt: '1',
      pay_mode: '1',
      // 体检日期不能选择今日以前
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() < Date.now() - 8.64e7;
        }
      },

      isShowPhone: false,
      companyList: [],
      packageValue: '',
      checkCustomList: [],
      corpname: '',
      isSuper: 0,
      goodsList: [],
      pay_mode: '',
      hasFb: false,
      goodsType: '',
    }
  },
  computed: {
    birthSel: {// 出生日期展示
      get () {
        if (this.formData.birth) {//不能为空
          // 时间戳妙转为毫秒
          return this.formData.birth;
        } else {
          return '';
        }

      },
      set (value) {
        // 毫秒转换为秒
        this.formData.birth = value;

      }
    },
    examdateSel: {// 体检日期展示
      get () {
        if (this.formData.examdate) {//不能为空
          // 时间戳妙转为毫秒
          return this.formData.examdate;
        } else {
          return '';
        }
      },
      set (value) {
        // 毫秒转换为秒
        this.formData.examdate = value;
      }
    }
  },
  props: ['type', 'isBo', 'formData', 'reportCode'],
  inject: ['handleRefresh'],
  mounted () {


    let userInfo = JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')) : ''
    this.isSuper = userInfo.isSuper
    setTimeout(() => {
      this.packageValue = this.formData.goods_id
      if (this.formData.sex != '') {
        this.formData.sex = Number(this.formData.sex)
      }

      this.formData.operator = userInfo.username

    }, 500)


    getUserGoodsList().then((res) => {
      if (res.code == 200) {
        let data = JSON.parse(Base64.decode(res.data))
        this.pay_mode = data.pay_mode

        let list = this.$t('goodsList')
        for (let i = 0; i < data.goodsList.length; i++) {
          const arr = list.filter((item) => item.type == data.goodsList[i].type)
          if (arr.length > 0) {
            data.goodsList[i].name = arr[0].name
          }
        }
        this.goodsList = data.goodsList
      }
    })
  },
  watch: {
    packageValue (newVal) {
      this.formData.package = newVal
      if (newVal == '1') {
        this.$emit('handleMuying', false)
        this.$emit('handleHasFb', false)
        this.$emit('handleHasAiFb', false)
        this.$emit('handleHasAiJk', false)
        this.$emit('handleHasBj', false)
        this.$emit('handleHasM22', false)
      }
      if (newVal == '2') {
        this.checkCustomList = this.formData.custom ? this.formData.custom : ['base', 'mlev']
        this.$emit('handleMuying', false)
        this.$emit('handleHasAiFb', false)
        this.$emit('handleHasAiJk', false)
        this.$emit('handleHasBj', false)
        this.$emit('handleHasM22', false)
      } else {
        this.checkCustomList = []
      }
      if (newVal == '3') {
        this.$emit('handleMuying', true)
        this.$emit('handleHasFb', false)
        this.$emit('handleHasAiFb', false)
        this.$emit('handleHasAiJk', false)
        this.$emit('handleHasBj', false)
        this.$emit('handleHasM22', false)
      }
      if (newVal == '5') {
        this.$emit('handleMuying', false)
        this.$emit('handleHasFb', false)
        this.$emit('handleHasAiFb', false)
        this.$emit('handleHasAiJk', true)
        this.$emit('handleHasBj', false)
        this.$emit('handleHasM22', false)
      }
      if (newVal == '6') {
        this.$emit('handleMuying', false)
        this.$emit('handleHasFb', false)
        this.$emit('handleHasAiFb', true)
        this.$emit('handleHasAiJk', false)
        this.$emit('handleHasBj', false)
        this.$emit('handleHasM22', false)
      }
      if (newVal == '7') {
        this.$emit('handleMuying', false)
        this.$emit('handleHasFb', false)
        this.$emit('handleHasAiFb', false)
        this.$emit('handleHasAiJk', false)
        this.$emit('handleHasBj', true)
        this.$emit('handleHasM22', false)
      }
      this.goodsList.map((item) => {
        if (item.goods_id == newVal && item.type == 'M22') {
          this.$emit('handleMuying', false)
          this.$emit('handleHasFb', false)
          this.$emit('handleHasAiFb', false)
          this.$emit('handleHasAiJk', false)
          this.$emit('handleHasBj', false)
          this.$emit('handleHasM22', true)
          this.formData.type = 'M22'
          this.goodsType = 'M22'
        }
      })

    },
    checkCustomList (newVal) {
      if (newVal.length > 0) {
        this.$emit('handleHasFb', newVal.indexOf('packageFB') == -1 ? false : true)
        this.hasFb = newVal.indexOf('packageFB') == -1 ? false : true
      }
    },
  },
  methods: {
    handlePackage (goods) {
      this.formData.goods_type = goods.type
      console.log("套餐修改了", goods, this.formData);
    },
    getAge (birthday, examdate) {
      let num = this.hasFb ? (864000000 - 86400000) : ((864000000 / 2) - 86400000)
      return Math.ceil(((examdate - birthday) - num) / 31536000000) - 1;
    },
    handleBirth (val) {

    },
    handleExamdate (val) {

    },
    getAgeBirth (birthday) {
      let num = this.packageValue == '6' ? 864000000 : (864000000 / 2)
      let nowTime = new Date().getTime()
      return Math.ceil(((nowTime - birthday) - num) / 31536000000) - 1;
    },
    check_num () {
      if (this.formData.yyuserid) {
        this.formData.yyuserid = this.formData.yyuserid.replace(/[^\a-\z\A-\Z0-9]/g, '');
      }
    },
    // 下一步
    next () {
      if (this.packageValue == '1') {
        this.$emit('handleMuying', false)
        this.$emit('handleHasFb', false)
        this.$emit('handleHasAiFb', false)
        this.$emit('handleHasAiJk', false)
        this.$emit('handleHasBj', false)
        this.$emit('handleHasM22', false)
      }
      if (this.packageValue == '3') {
        this.$emit('handleHasFb', false)
        this.$emit('handleMuying', true)
        this.$emit('handleHasAiFb', false)
        this.$emit('handleHasAiJk', false)
        this.$emit('handleHasBj', false)
        this.$emit('handleHasM22', false)
      }
      if (this.packageValue == '2') {
        this.$emit('handleMuying', false)
        this.$emit('handleHasAiFb', false)
        this.$emit('handleHasAiJk', false)
        this.$emit('handleHasBj', false)
        this.$emit('handleHasFb', this.checkCustomList.indexOf('packageFB') == -1 ? false : true)
        this.formData.custom = this.checkCustomList
        this.$emit('handleHasM22', false)
      }
      if (this.packageValue == '5') {
        this.$emit('handleMuying', false)
        this.$emit('handleHasFb', false)
        this.$emit('handleHasAiFb', false)
        this.$emit('handleHasAiJk', true)
        this.$emit('handleHasBj', false)
        this.$emit('handleHasM22', false)
      }
      if (this.packageValue == '6') {
        this.$emit('handleMuying', false)
        this.$emit('handleHasFb', false)
        this.$emit('handleHasAiFb', true)
        this.$emit('handleHasAiJk', false)
        this.$emit('handleHasBj', false)
        this.$emit('handleHasM22', false)
      }

      if (this.packageValue == '7') {
        this.$emit('handleMuying', false)
        this.$emit('handleHasFb', false)
        this.$emit('handleHasAiFb', false)
        this.$emit('handleHasAiJk', false)
        this.$emit('handleHasBj', true)
        this.$emit('handleHasM22', false)
      }
      if (this.goodsType == 'M22') {
        this.$emit('handleMuying', false)
        this.$emit('handleHasFb', false)
        this.$emit('handleHasAiFb', false)
        this.$emit('handleHasAiJk', false)
        this.$emit('handleHasBj', false)
        this.$emit('handleHasM22', true)
      }

      this.formData.goods_id = this.packageValue
      // 报告编码和输入框一致

      this.$refs.personal.validate((isSuc, err) => {
        // 验证通过且确认输入也通过
        if (isSuc) {
          if (this.packageValue == '' || this.packageValue == undefined || this.packageValue == 'undefined') {
            this.$myAlert({
              title: this.$t('open.f32'),
              content: this.$t('open.f33')
            });
            return
          }
          if (this.packageValue == "3" && this.formData.sex != 2) {
            this.$myAlert({
              title: 'Kindly reminder',
              content: 'The [AI]Postpartum Risk (Mother & Newborn) is for females only and requires maternity and newborn information.'
            });
            return
          }
          this.$emit('stopQuery');

          // 成功进入下一步
          if (this.packageValue == 6) {
            this.$emit('checkTab', 'FbQuestions');
          } else if (this.goodsType == 'M22') {
            this.$emit('checkTab', 'Bodily');
          } else {
            this.$emit('checkTab', 'Questions');
          }

        }
      });

    },

    // 手机号确认
    mobileConfirmVerify () {
      if (this.mobileConfirm != this.formData.mobile) {
        this.mobileConfirmErr = true;
        return false;
      } else {
        this.mobileConfirmErr = false;
        return true;
      }
    },

    // 企业用户id确认
    yyuseridConfirmVerify () {
      if (this.yyuseridConfirm != this.formData.yyuserid) {
        this.yyuseridConfirmErr = true;
        return false;
      } else {
        this.yyuseridConfirmErr = false;
        return true;
      }
    },

    // 距今year年的时间戳 参数:year:距今年份
    limitTime (year) {
      let nowDate = new Date();
      return new Date((nowDate.getFullYear() - year) + '/' + (nowDate.getMonth() + 1) + '/' + nowDate.getDate()).getTime();
    },

    // 手机号必须输入数字
    phoneMustNumber (value) {
      this.formData.mobile = value.replace(/\D/g, '');
      this.isShowPhone = true
      setTimeout(() => {
        this.isShowPhone = false
      }, 5000)
    },
    phoneConfirmMustNumber (value) {
      this.mobileConfirm = value.replace(/\D/g, '');
    }
  }
}
</script>

<style scoped>
.el-form>>>.el-form-item__label {
  display: block;
  line-height: 36px;
}

.el-form .el-input>>>input,
.el-form>>>.el-input__inner {
  height: 36px;
  line-height: 16px;
  font-size: 14px;
}

.el-form>>>.el-form-item__content,
.el-form>>>.el-input__icon {
  line-height: 36px;
}

.product .el-checkbox,
.product>>>.el-checkbox__input {
  position: absolute;
  top: 0;
  left: 0;
}

.product>>>.el-checkbox__inner {
  width: 18px;
  height: 16px;
  border-radius: 6px 0 6px 0;
}

.product>>>.el-checkbox__inner::after {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)rotate(45deg)scaleY(0);
}

.product>>>.el-checkbox__input.is-checked .el-checkbox__inner::after {
  transform: translate(-50%, -50%)rotate(45deg)scaleY(1);
}

.product>>>.el-checkbox__input.is-checked .el-checkbox__inner,
.product>>>.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  border-color: #6681FA;
  background-color: #6681FA;
}

.packages>>>.el-checkbox__label {
  display: none;
}

.packages>>>.el-form-item__content {
  width: 100%;
}

.packages>>>.el-form-item__error {
  position: absolute;
  top: 28px;
  left: 200px;
}

.el-form>>>.el-checkbox__label {
  font-size: 14px;
}

.wid-100>>>.el-form-item__content {
  flex: 1;
}

.el-form>>>.is-checked .el-checkbox__input.is-disabled+span.el-checkbox__label {
  color: #6883FB;
}

.el-form>>>.is-checked .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
  border-color: #6681FA;
  background-color: #6681FA;
}

.el-form>>>.el-radio.is-bordered.is-checked {
  border-color: #6681FA
}

.el-form>>>.is-checked .el-radio__input.is-disabled+span.el-radio__label {
  color: #6681FA;
}

.el-form>>>.is-checked .el-radio__input.is-disabled .el-radio__inner,
.el-radio__input.is-disabled.is-checked .el-radio__inner {
  background-color: #6681FA;
}

.goods-type>>>.el-form-item__content {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
}
</style>
<style lang="scss" scoped>
.goods-type {
  .el-form-item__content {
    >div {
      margin-right: 12px;
    }

    &::before {
      display: none;
      content: none;
    }
  }
}

.personal {
  padding-top: 50px;

  .open-item-tip {
    position: absolute;
    top: -40px;
    left: 0px;
    width: 438px;
    height: 40px;
    padding: 0 16px;
    line-height: 40px;
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #293033;
    background-color: #FFFFFF;
    box-shadow: 0px 1px 4px 0px #D7E0ED;
    border-radius: 2px;
  }

  .insert-title {
    position: relative;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #293033;
    line-height: 16px;
    padding-bottom: 15px;
    border-bottom: 1px solid #E5E8F5;

    &::before {
      position: absolute;
      left: -24px;
      top: 0;
      content: '';
      width: 4px;
      height: 20px;
      background-color: #6883FB;
    }
  }

  .el-form {
    .el-form-item {
      width: 50%;
      margin-right: 0;
      margin-bottom: 12px;

      .label {
        display: flex;
        // justify-content: flex-end;
        color: #777F8F;
      }

      .el-input,
      .el-select {
        // width: 420px;
        width: 500px;
      }

      .tip {
        line-height: 12px;
        margin-top: 6px;
        color: #ACAEB3;
      }

      .product {
        width: 193px;
        height: 56px;
        line-height: 18px;
        border-radius: 6px 0 6px 0;
        cursor: pointer;

        .name {
          top: 50%;
          left: 50%;
          width: 100%;
          transform: translate(-50%, -50%);
        }

        &.basic {
          color: #40444D;
          background-color: #F0F4F7;
        }

        &.PLM {
          color: #C07E37;
          background-color: #FCF9F0;
        }

        &.comorbidity {
          color: #C92B5C;
          background-color: #FCF5F2;
        }

        &.illness {
          color: #5843BE;
          background-color: #F6F5FC;
        }

        &.health {
          color: #1955AF;
          background-color: #F0F6FC;
        }
      }

      p.base-count {
        width: 193px;
        margin-bottom: 16px;
      }

      .count {
        font-size: 13px;
        line-height: 13px;
        color: #777F8F;
        margin-top: 6px;
      }
    }

    .packages {
      width: 100%;
      margin: 16px 0 0;

      .el-checkbox-group {
        display: flex;
        flex-wrap: wrap;

        div.qjc-fl {
          margin-right: 16px;
        }

        .c-danger {
          color: #FF4C52;
        }
      }
    }

    h3 {
      margin: 40px 0 24px;
    }

    .footer-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 40px;
    }

    .next {
      padding: 0 10px;
      // width: 96px;
      height: 34px;
      border-radius: 17px;
      border: none;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 400;
      color: #fff;
    }

    .prev {
      // width: 96px;
      padding: 0 10px;
      height: 34px;
      border-radius: 17px;
      border: 1px solid #6883FB;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #6883FB;
      background-color: #fff;
      margin-right: 24px;
    }
  }
}

.package-radio {
  margin-bottom: 8px;
}

.wid-100 {
  display: flex;
  width: 100% !important;
}

.package {}

.package-radio {
  // width: 420px;
  width: 540px;

  .el-radio.is-bordered {
    // width: 420px;
    width: 500px;
  }
}

.custom-box {
  width: 100%;
  padding: 12px;
  border: 1px solid #DBDDE6;
}

.wid-100 .el-form-item__content {
  flex: 1;
}

.radio-option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #00000030;
  padding: 0 16px;

  >div {
    flex: 1;
    overflow: hidden;
  }

  .radio-option-num {
    flex: 0 0 auto;
    white-space: nowrap;
    text-align: right;
  }
}
</style>
