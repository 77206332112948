// 常用方法

//验证
//手机(大陆)
export var isPhone = (phonevalue) => {
   var phoneReg = /^1\d{10}$/;
   if(phoneReg.test(phonevalue)){
	   return true;
   }else{
	   return false;
   }
}

//手机(香港)
export const isHKPhone = (phonevalue) => {
    var reg = /^\d{8}$/;
    if (reg.test(phonevalue)) {
        return true;
    } else {
        return false;
    }
};
//邮箱
export var isEmail = (emailValue) => {
	var mailReg = /^(\w-*\.*)+@(\w-?)+(\.\w{2,})+$/;
	if(mailReg.test(emailValue)){
		return true;
	}else{
		return false;
	}
}

//密码验证
export var pwdReg = (pwdValue) => {
	//包含两种及以上字符且不可为汉字
	// var pwdReg=/^(?![0-9]+$)(?![a-z]+$)(?![A-Z]+$)(?!([^(0-9a-zA-Z)])+$).{6,}$/;
	var pwdReg=/^(?![0-9]+$)(?![a-z]+$)(?![A-Z]+$)(?!([^(0-9a-zA-Z)])+$)/;
	if(pwdReg.test(pwdValue) && !hanReg(pwdValue)){
		return true;
	}else{
		return false;
	}
}
//禁止输入中文汉字
export var hanReg = (value) => {
	// var chReg = /\p{Unified_Ideograph}/ug;  //npm会报错
	var chReg = /[\u4e00-\u9fa5]/g;
	if(value.search(chReg) >= 0){
		return true;
	}else{
		return false;
	}
}
// 只能为数字
export var isNumber = (value) => {
	var reg = /\D/g;
	if(reg.test(value)){
		return false;
	}else{
		return true;
	}
}

export var timesStr = (date) => {
	let d = new Date(date * 1000);    //根据时间戳生成的时间对象
	let year = d.getFullYear();
	let month = d.getMonth() + 1;
	let day = d.getDate();
	let time = year + '/' + month + '/' + day
	
	return time
}

export var formatDate = (now) => { 
     var year=now.getFullYear(); 
     var month=now.getMonth()+1;
     var date=now.getDate(); 
     var hour=now.getHours(); 
     var minute=now.getMinutes(); 
     var second=now.getSeconds(); 
     return year+"-"+month+"-"+date+" "+hour+":"+minute+":"+second; 
} 

export var dateStrChangeTimeTamp = (dateStr) => {
   dateStr = dateStr.substring(0,10);
   dateStr = dateStr.replace(/-/g,'/');
   var timeTamp = new Date(dateStr).getTime() / 1000;
   return timeTamp
}